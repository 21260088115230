import axios from 'axios';
import Cookies from 'js-cookie';
import {TOKEN_EXPIRE_TIME, TG_TOKEN, WEBSITE_URL} from "./variables";

const axiosInstance = axios.create({
  headers: {
    Origin: WEBSITE_URL,
    Accept: 'application/json', // Set the expected response type
    'Content-Type': 'application/json', // Set the content type of the request
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // Assuming you have an endpoint to refresh the token and a method to store it
      const tokenResponse = await axios.post('https://user.gotosafar.com/api/v1/refresh-token', {}, {headers: {Authorization: `Bearer ${Cookies.get(GTS_TOK)}`}});
      const newAccessToken = tokenResponse.data.token;
      Cookies.set(TG_TOKEN, newAccessToken, { expires: TOKEN_EXPIRE_TIME }); // Update the access token in the cookie
      // Cookies.set(TG_TOKEN, newAccessToken, { expires: TOKEN_EXPIRE_TIME }); // Update the access token in the cookie
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
